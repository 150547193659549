.titleImg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 0.373333rem 0 0.293333rem 0;
  border-bottom: 1px solid rgba(241, 242, 243, 1);
  width: 100%;
}

:global(.hairlines) .titleImg {
  border-bottom: 0.5px solid rgba(241, 242, 243, 1);
}

.link {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 0.666667rem;
}

.title {
  display: block;
  margin: 0 0.426667rem 0.186667rem 0;
  min-height: 1.333333rem;
  max-height: 2rem;
  font-size: 0.48rem;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(33, 34, 35, 1);
  overflow: hidden;
}

.img {
  position: relative;
  display: block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.img img {
  width: 3.013333rem;
  height: 2rem;
  border-radius: 0.053333rem;
}

.imgIcon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0.16rem;
  bottom: 0.24rem;
  width: 1.173333rem;
  height: 0.533333rem;
  background: rgba(33, 34, 35, .5);
  border-radius: 0.266667rem;
}

.imgIcon span:first-child {
  margin-right: 0.106667rem;
  margin-bottom: 0.053333rem;
  width: 0.266667rem;
  height: 0.266667rem;
  /* background: url(../assets/images.png) no-repeat; */
  background-size: contain;
}

.imgIcon span:last-child {
  height: 0.48rem;
  line-height: 0.48rem;
  font-size: 0.266667rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.videoIcon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0.16rem;
  bottom: 0.24rem;
  width: 1.413333rem;
  height: 0.533333rem;
  background: rgba(33, 34, 35, .5);
  border-radius: 0.266667rem;
}

.videoIcon span:first-child {
  margin-right: 0.106667rem;
  margin-bottom: 0.026667rem;
  width: 0.266667rem;
  height: 0.266667rem;
  background: url(./smallplay.png) no-repeat;
  background-size: contain;
}

.videoIcon span:last-child {
  height: 0.48rem;
  line-height: 0.48rem;
  font-size: 0.266667rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.topicLink {
  display: inline-block;
  margin: 0 0.133333rem 0.106667rem 0;
  width: 0.746667rem;
  height: 0.453333rem;
  line-height: 0.453333rem;
  /* background: url(../assets/specialIcon.png) no-repeat; */
  background-size: contain;
  vertical-align: bottom;
}

.themeLink {
  display: inline-block;
  margin: 0 0.133333rem 0.106667rem 0;
  width: 0.746667rem;
  height: 0.453333rem;
  line-height: 0.453333rem;
  /* background: url(../assets/topicIcon.png) no-repeat; */
  background-size: contain;
  vertical-align: bottom;
}

.info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 0.453333rem;
}

.info span {
  margin-right: 0.266667rem;
}

.stickMark {
  height: 0.453333rem;
  line-height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC;
  font-weight: 400;
}

.openApp {
  height: 0.453333rem;
  line-height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(245, 67, 67, 1);
}

.wap,
.source,
.count {
  height: 0.453333rem;
  line-height: 0.453333rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: rgba(161, 165, 172, 1);
}

.wap,
.source {
  font-family: PingFangSC;
}

.imageAnimateOpacityZero {
  opacity: 0;
}

.imageAnimateOpacityOne {
  opacity: 0;
  -webkit-animation-name: imageAnimate;
  animation-name: imageAnimate;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}